<template>
  <!-- If don't saved -->
  <ModalEmpty
    v-if="isOpen"
    @close="
      () => {
        isOpen = false
        leaveRoute = ''
      }
    "
  >
    <div class="w-400 p-30 flex ai-c jc-c fd-c ta-center">
      <h1 class="title">Не сохранили изменения</h1>
      <div class="t-grey-dark fs-16 mt-5 mb-30">
        Ваши изменения не вступят в силу если их не сохранить, все равно хотите уйти?
      </div>

      <div class="flex ai-c jc-sb ggap-20">
        <button class="btn cancel" @click="leaveAction(false)">Не сохранять и уйти</button>
        <button class="btn green" @click="leaveAction(true)">Сохранить и уйти</button>
      </div>
    </div>
  </ModalEmpty>

  <div class="notifications pos-r z1 pt-20 pb-20">
    <BaseLoad v-if="isLoad" class="pos-a grid jc-c z99999 white-transparent" />

    <div class="flex ai-c jc-sb">
      <h3 class="m-0">Настройки компании</h3>
      <button type="button" @click.prevent.stop="isNewCompany = !isNewCompany" class="btn transparent-grey sm pl-10">
        <FormFakeSwitch :isTrue="isNewCompany" />
        Добавить нового
      </button>
    </div>

    <form id="company-add" class="box mt-20 notifications__rows grid gtc-1 p-20 ggap-20">
      <div class="grid gtc-1 ggap-20 ai-fs">
        <FormAutocomplete
          label="Проект"
          v-if="!isNewCompany"
          required
          placeholder="-"
          :options="optionsProjects"
          v-model="project"
        />
        <FormInput
          class="sm w-100"
          label="Название проекта"
          v-if="isNewCompany"
          type="text"
          v-model="formData.project"
          required
        />
      </div>

      <div class="grid gtc-1 ggap-20 ai-fs" v-if="project?.id || isNewCompany">
        <FormInput
          class="sm w-100"
          label="Банк"
          type="text"
          v-model="formData.bank"
          required
          :disabled="!isNewCompany && !project"
        />
      </div>

      <div class="grid gtc-2 ggap-20 ai-fs" v-if="project?.id || isNewCompany">
        <FormInput
          class="sm w-100"
          label="ИИН/БИН"
          type="number"
          :disabled="!isNewCompany && !project"
          :maxlength="12"
          v-model="formData.bin"
          required
        />
        <FormInput
          class="sm w-100"
          label="БИК"
          minlength="8"
          maxlength="11"
          :disabled="!isNewCompany && !project"
          type="number"
          v-model="formData.bik"
          required
        />
      </div>

      <div class="grid gtc-2 ggap-20 ai-fs" v-if="project?.id || isNewCompany">
        <FormSumm v-model="formData.kzt" :disabled="!isNewCompany && !project" :showcurrency="false" label="Тенге" />
        <FormSumm v-model="formData.usd" :disabled="!isNewCompany && !project" :showcurrency="false" label="Доллар" />
      </div>
      <div class="grid gtc-2 ggap-20 ai-fs" v-if="project?.id">
        <FormSumm v-model="formData.eur" :disabled="!isNewCompany && !project" :showcurrency="false" label="Евро" />
        <FormSumm v-model="formData.rub" :disabled="!isNewCompany && !project" :showcurrency="false" label="Рубли" />
      </div>

      <div class="grid pos-r ggap-20" v-if="project?.id || isNewCompany">
        <FormAutocompleteSearch
          class="f-1"
          label="Выберите директора"
          placeholder="-"
          :disabled="!isNewCompany && !project"
          :options="optionsUsers"
          :isLoad="isUserLoad"
          :isMultiple="false"
          required
          @search="searchUsers($event)"
          v-model="formData.director"
        >
          <template #single="{ item }">
            <div v-if="item && item.id" class="grid gtc-auto-1 ggap-10">
              <div class="cover ava-24">
                <img :src="item.avatar || require('@/assets/img/ava.svg')" />
              </div>
              <div class="grid gtc-auto-1 ai-c ggap-10">
                <div class="cut">{{ item.text }}</div>
              </div>
            </div>
          </template>
        </FormAutocompleteSearch>
      </div>
    </form>

    <div class="flex ai-c jc-sb ggap-20 mt-20">
      <button class="btn cancel" @click.prevent="getSettings()" :disabled="isNoChanged">Сбросить изменения</button>
      <button class="btn primary" @click.prevent="saveSettings()" :disabled="isNoChanged">Сохранить изменения</button>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { onBeforeRouteLeave, useRouter } from 'vue-router'
import { defineEmits, ref, computed, nextTick, watch } from 'vue'
import {
  BaseLoad,
  ModalEmpty,
  FormAutocomplete,
  FormFakeSwitch,
  FormInput,
  FormAutocompleteSearch,
  FormSumm
} from '@/components'
import { isInvalidForm, $busEmit } from '../../plugins'
import notificationsSettings from '@/api/modules/notifications-settings'

// Emits
const emits = defineEmits(['loadAction'])

// Data
const defaultRef = { project: '', bin: '', bank: '', bik: '', director: '', kzt: '', usd: '', eur: '', rub: '' }
const optionsProjects = computed(() => store.getters.projects || [])
const router = useRouter()
const store = useStore()
const isLoad = ref(false)
const isOpen = ref(false)
const isUserLoad = ref(false)
const leaveRoute = ref('')
const project = ref()
const isNoChanged = ref(true)
const isNewCompany = ref(false)
const formData = ref(defaultRef)

// Computed
const optionsUsers = computed(() => store.getters.users || [])

// Watch
watch(isNewCompany, () => {
  isNoChanged.value = !isNewCompany.value
  project.value = ''
  formData.value = { project: '', bin: '', bank: '', bik: '', director: '' }
})
watch(project, () => {
  getSettings()
  if (!project?.value?.id) {
    isNoChanged.value = true
  }
})
watch(formData.value, () => (isNoChanged.value = false))

// Created
store.dispatch('GET_PROJECTS')

// Router before leave
onBeforeRouteLeave((to, from, next) => {
  if (!isNoChanged.value && !leaveRoute.value) {
    isOpen.value = true
    leaveRoute.value = to.fullPath
  } else {
    next()
  }
})

// Methods
async function getSettings() {
  if (project.value?.id) {
    try {
      emits('loadAction', true)
      const { data } = await notificationsSettings.getCompany(project.value?.id)
      data.data.finance_limit.map((item) => (formData.value[item.currency] = Number(item.limit) || 0))

      formData.value.eur = Number(data.data.finance_limit?.find((item) => item.currency === 'eur')?.limit) || ''
      formData.value.kzt = Number(data.data.finance_limit?.find((item) => item.currency === 'kzt')?.limit) || ''
      formData.value.usd = Number(data.data.finance_limit?.find((item) => item.currency === 'usd')?.limit) || ''
      formData.value.rub = Number(data.data.finance_limit?.find((item) => item.currency === 'rub')?.limit) || ''

      formData.value.bank = data.data?.bank
      formData.value.bik = data.data?.bik
      formData.value.bin = data.data?.bin
      formData.value.director = {
        id: data?.data?.director?.id,
        text: data?.data?.director?.fullname,
        position: data?.data?.director?.specialization || 'Нет должности',
        value: data?.data?.director?.id,
        avatar: data?.data?.director?.avatar
      }
      await nextTick()
      isNoChanged.value = true
      emits('loadAction', false)
    } catch (error) {
      console.log(error)
    }
  }
}

const saveSettings = () => (isNewCompany.value ? addCompany() : updateSettings())

async function addCompany() {
  if (isInvalidForm('company-add')) return
  try {
    emits('loadAction', true)
    const finance_limit = []
    finance_limit.push({ currency: 'eur', limit: formData.value?.eur })
    finance_limit.push({ currency: 'kzt', limit: formData.value?.kzt })
    finance_limit.push({ currency: 'usd', limit: formData.value?.usd })
    finance_limit.push({ currency: 'rub', limit: formData.value?.rub })
    await notificationsSettings.addCompany({
      ...formData.value,
      finance_limit,
      director_id: formData.value?.director?.id
    })
    isNoChanged.value = true
    await nextTick()
    emits('loadAction', false)
    $busEmit('setToast', {
      type: 'green',
      icon: 'check-circle',
      message: 'Компания была успешно добавлена!'
    })
    isNewCompany.value = false
    store.dispatch('GET_PROJECTS')
  } catch (error) {
    console.log(error)
    $busEmit('setToast', {
      type: 'red',
      icon: 'close-circle',
      message: 'При создании компании произошла ошибка!'
    })
  }
}

async function updateSettings() {
  if (isInvalidForm('company-add')) return
  try {
    emits('loadAction', true)
    const finance_limit = []
    finance_limit.push({ currency: 'eur', limit: formData.value?.eur })
    finance_limit.push({ currency: 'kzt', limit: formData.value?.kzt })
    finance_limit.push({ currency: 'usd', limit: formData.value?.usd })
    finance_limit.push({ currency: 'rub', limit: formData.value?.rub })
    const payload = {
      ...formData.value,
      director_id: formData.value?.director?.id,
      finance_limit: finance_limit
    }
    delete payload.project
    await notificationsSettings.updateCompany(project.value?.id, payload)
    isNoChanged.value = true
    await nextTick()
    $busEmit('setToast', {
      type: 'green',
      icon: 'check-circle',
      message: 'Компания была успешно обновлена!'
    })
    emits('loadAction', false)
  } catch (error) {
    console.log(error)
    $busEmit('setToast', {
      type: 'red',
      icon: 'close-circle',
      message: 'При обновлении данных компании произошла ошибка!'
    })
  }
}

async function leaveAction(isSave = false) {
  isOpen.value = false
  if (!isSave) return router.push(leaveRoute.value)

  try {
    await updateSettings(false)
    router.push(leaveRoute.value)
  } catch (error) {
    console.log(error)
  }

  leaveRoute.value = ''
}

async function searchUsers(searchText) {
  isUserLoad.value = true
  await store.dispatch('GET_USERS', searchText)
  isUserLoad.value = false
}
</script>

<style lang="scss">
.notifications {
  &__rows {
    overflow: hidden;
  }

  &__row {
    text-align: left;
    background-color: transparent;
    border: 0;
    border-top: var(--b1);
    color: #999;
    cursor: pointer;

    &:hover {
      background-color: var(--grey-l);
      color: var(--text);
    }
    &.active {
      color: var(--text);
    }
    &:first-child {
      border: 0;
    }
  }
}

.dark {
  .notifications {
    &__rows {
      .title {
        color: #999;
      }
    }

    &__row {
      color: #777;

      &:hover {
        background-color: var(--grey-l);
        color: var(--text);
      }
      &.active {
        color: var(--text);
      }
    }
  }
}
</style>
